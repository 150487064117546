<template>
    <v-app id="Layout">
        <v-app-bar app color="blue darken-3" dark clipped-left>
            <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
            <v-toolbar-title style="width: 100px;cursor:pointer" class="ml-0 pl-4" @click="$route.path!='/home'?$router.replace('/home'):null">闪云</v-toolbar-title>
            <div class="menu">
                <v-btn class="ma-2" text @click="$route.path!='/home'?$router.replace('/home'):null">
                    <v-icon left>mdi-home</v-icon>首页
                </v-btn>
                <v-btn class="ma-2" text @click="$router.push('/key')">
                    <v-icon left>mdi-shield-key</v-icon>密钥
                </v-btn>

                <v-btn class="ma-2" v-if="roles=='admin'" text @click="$router.push('/userList')">
                    <v-icon left>mdi-account-multiple</v-icon>用户管理
                </v-btn>
                <v-btn class="ma-2" v-if="roles=='admin'" text @click="$router.push('/adminNet')">
                    <v-icon left>mdi-format-list-bulleted-square</v-icon>平台虚拟网
                </v-btn>
            </div>
            <v-spacer></v-spacer>
            <!-- <v-btn color="warning" small @click="logout">登出</v-btn> -->

            <v-menu bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <!-- <v-btn color="warning" small @click="logout">登出</v-btn> -->
                    <v-btn dark v-bind="attrs" v-on="on" text>
                        <v-icon left>mdi-apps</v-icon>设置
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="logout">
                        <v-list-item-title>登出</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/upPass">
                        <v-list-item-title>修改密码</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-main class="grey lighten-4 fill-height">
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>
        <!-- <v-footer color="blue darken-3" app>
            <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
        </v-footer> -->
    </v-app>
</template>

<script>
    // @ is an alias to /src
    import { post, obj, clearCookie, setCookie, tips } from '@/facade'

    export default {
        name: 'Layout',
        data() {
            return {
                drawer: null,
                user: {},
                item: 0,
                menus: [],
                form: {
                    uid: obj.uid
                },
                roles: 'member',
                networks: [

                ],
            }
        },

        async created() {
            await this.getNetworkList()
            await this.getRoles();
            // await this.getNetworkList();
        },

        methods: {
            // 获取虚拟网列表
            async getNetworkList() {
                const data = await post('/vnet/network/list', this.form)
                if (data.code == 'ok') {
                    this.netWorks = data.networks

                } else {
                    tips('error', data.message)
                }
            },
            async logout() {
                const data = await post('/user/logout')
                if (data.code == 'ok') {
                    clearCookie('token');
                    clearCookie('uid');

                    obj.token = "";
                    obj.uid = "";
                    this.$router.replace({ path: "/login" })
                }
            },
            /**获取用户信息 */
            async getRoles() {
                const data = await post('/user/user')
                if (data.code == 'ok') {
                    this.user = data.user
                    const roles = data.user.roles[0]
                    this.roles = roles
                    setCookie('roles', roles, 1);
                } else {
                    // tips('error', data.message)
                    this.$router.replace('/login')
                }
            },

        }
    }
</script>
<style scoped>
    .menu{
        display: flex;
        display: -webkit-flex;
        overflow:auto
    }
</style>